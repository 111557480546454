












import {
  defineComponent, PropType, ref, watch,
} from '@vue/composition-api';
import { Customer } from '@/models/Customer';
import RdDictionarySelector from '@/components/base/inputs/RdDictionarySelector.vue';
import { UrlConstants } from '@/helpers/UrlConstants';
import { SimpleModel } from '@/models/base/SimpleModel';

export default defineComponent({
  name: 'RdCustomerSelector',
  components: { RdDictionarySelector },
  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: 'Выберите заказчика',
    },
    value: {
      type: Object as PropType<SimpleModel>,
      required: false,
      default: null,
    },
  },
  emits: ['input'],
  setup: (props, ctx) => {
    const url = `${UrlConstants.baseApi}/v1/customers/select`;
    const type = Customer;
    const customer = ref(props.value);

    watch(customer, (current) => {
      ctx.emit('input', current);
    });

    return {
      type,
      url,
      customer,
    };
  },
});
