











import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'RdLoadingButton',
  emits: ['click'],
  props: {
    color: {
      type: String,
      default: '',
    },
  },
  setup: (props, ctx) => {
    const isLoading = ref(false);

    const onClick = async () => {
      isLoading.value = true;
      try {
        if (ctx.listeners.click != null) {
          await ctx.listeners.click();
        }
      } finally {
        isLoading.value = false;
      }
    };

    return {
      isLoading,
      onClick,
    };
  },
});
