













import {
  computed, defineComponent, ref, watch,
} from '@vue/composition-api';
import RdDictionarySelector from '@/components/base/inputs/RdDictionarySelector.vue';
import { UrlConstants } from '@/helpers/UrlConstants';
import { Station } from '@/models/Station';

export default defineComponent({
  name: 'RdStationSelector',
  components: { RdDictionarySelector },
  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: 'Выберите станцию',
    },
    value: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ['input'],
  setup: (props, { emit }) => {
    const url = `${UrlConstants.baseApi}/v1/stations/select`;
    const type = Station;
    const reactiveValue = ref(props.value);

    watch(reactiveValue, (current: any) => {
      emit('input', current);
    });

    return {
      reactiveValue,
      type,
      url,
    };
  },
});
