import { Customer } from '@/models/Customer';
import { Type } from 'class-transformer';

export class BusinessContract {
  public id: string | null = null

  public number = ''

  @Type(() => Date)
  public dateBegin: Date | null = null

  @Type(() => Date)
  public dateEnd: Date | null = null

  @Type(() => Customer)
  public customer: Customer | null = null

  constructor(init?: Partial<BusinessContract>) {
    Object.assign(this, init);
  }
}
