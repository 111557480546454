import { Type } from 'class-transformer';
import { TrainTimeTable } from '@/models/train-schedule/TrainTimeTable';

export class TrainScheduleWithTrainTimeTable {
  public id = ''

  public number = ''

  @Type(() => Date)
  public dateBegin!: Date

  @Type(() => Date)
  public dateEnd: Date | null = null

  @Type(() => TrainTimeTable)
  public trainTimeTables: Array<TrainTimeTable> = []
}
