import { Station } from '@/models/Station';
import { Type } from 'class-transformer';

export class TrainSchedule {
  public id!: string

  public number!: string

  @Type(() => Station)
  public beginStation!: Station

  @Type(() => Station)
  public endStation!: Station

  public departureTime!: string

  constructor(init?: Partial<TrainSchedule>) {
    Object.assign(this, init);
  }
}
