


















































































































































































































































































































































import {
  computed,
  defineComponent,
  reactive,
  ref, watch,
} from '@vue/composition-api';
import { BusinessContract } from '@/models/BusinessContract';
import RdDataTable from '@/components/base/data/RdDataTable.vue';
import { DataSource } from '@/models/base/data/DataSource';
import dateFormats from '@/helpers/DateFormats';
import { DataSourceColumn } from '@/models/base/data/DataSourceColumn';
import { TrainSchedule } from '@/models/train-schedule/TrainSchedule';
import RdDatePicker from '@/components/base/inputs/RdDatePicker.vue';
import { UrlConstants } from '@/helpers/UrlConstants';
import RdCustomerSelector from '@/components/base/inputs/RdCustomerSelector.vue';
import {
  addSeconds, addYears, format, formatDuration,
} from 'date-fns';
import RdStationSelector from '@/components/base/inputs/RdStationSelector.vue';
import { Container } from '@/Container';
import { HttpService } from '@/services/network/HttpService';
import { SimpleModel } from '@/models/base/SimpleModel';
import { AxiosResponse } from 'axios';
import { TrainScheduleWithTrainTimeTable } from '@/models/train-schedule/TrainScheduleWithTrainTimeTable';
import { Type } from 'class-transformer';
import { TrainTimeTable } from '@/models/train-schedule/TrainTimeTable';
import RdLoadingButton from '@/components/base/buttons/RdLoadingButton.vue';

type CustomerWithTrains = SimpleModel & {
  trains: Array<SimpleModel>
};
type TrainScheduleMigrateRequest = {
  contractId: string,
  customerId: string,
  trains: Array<string>,
};

type TrainTimeTableRequest = {
  trainTimeTables: Array<TrainTimeTable>
};

class TrainScheduleWithTrainTimeTableResponse {
  @Type(() => TrainScheduleWithTrainTimeTable)
  trainScheduleWithTrainTimeTables: Array<TrainScheduleWithTrainTimeTable> = []
}

class ContractResponse {
  @Type(() => BusinessContract)
  businessContract!: BusinessContract
}

export default defineComponent({
  name: 'Contracts',
  components: {
    RdLoadingButton,
    RdStationSelector,
    RdCustomerSelector,
    RdDatePicker,
    RdDataTable,
  },
  setup: () => {
    const httpService = Container.resolve<HttpService>("HttpService");
    const { toDate, toDateRange, secondsToTime } = dateFormats();

    const selectedContractId = ref('');
    const createContractDialogIsOpen = ref(false);
    const currentStep = ref(1);
    const trainTimeTableTab = ref(0);

    const dataSource = new DataSource<BusinessContract>({
      baseUrl: `${UrlConstants.baseApi}/v1/business-contracts`,
      propertyResponseName: 'businessContracts',
      argumentType: BusinessContract,
      columns: [
        new DataSourceColumn<BusinessContract>({
          propertyName: 'number',
          propertyLabel: 'Контракт №',
          dataFilter: null,
          filterable: true,
          sortable: true,
          groupable: false,
          width: null,
          dataSorter: null,
        }),
        new DataSourceColumn<BusinessContract>({
          propertyName: 'dateBegin',
          propertyLabel: 'Дата начала',
          dataFilter: null,
          filterable: true,
          sortable: true,
          groupable: false,
          width: null,
          dataSorter: null,
          formatter: (data) => {
            if (data.dateBegin == null) {
              return '';
            }
            return toDate(data.dateBegin);
          },
        }),
        new DataSourceColumn<BusinessContract>({
          propertyName: 'dateEnd',
          propertyLabel: 'Дата окончания',
          dataFilter: null,
          filterable: true,
          sortable: true,
          groupable: false,
          width: null,
          dataSorter: null,
          formatter: (data) => {
            if (data.dateEnd == null) {
              return '';
            }
            return toDate(data.dateEnd);
          },
        }),
        new DataSourceColumn<BusinessContract>({
          propertyName: 'actions',
          propertyLabel: 'Действия',
          dataFilter: null,
          filterable: false,
          sortable: false,
          groupable: false,
          width: null,
          dataSorter: null,
        }),
      ],
    });

    const trainScheduleUrl = computed(() => `${UrlConstants.baseApi}/v1/train-schedules/${selectedContractId.value}`);

    const trainScheduleDataSource = new DataSource<TrainSchedule>({
      baseUrl: trainScheduleUrl.value,
      propertyResponseName: 'trainSchedules',
      argumentType: TrainSchedule,
      columns: [
        new DataSourceColumn<TrainSchedule>({
          propertyName: 'number',
          propertyLabel: 'Номер',
          dataFilter: null,
          filterable: true,
          sortable: true,
          groupable: false,
          width: null,
          dataSorter: null,
        }),
        new DataSourceColumn<TrainSchedule>({
          propertyName: 'beginStation',
          propertyLabel: 'Станция отправления',
          dataFilter: null,
          filterable: true,
          sortable: true,
          groupable: false,
          width: null,
          dataSorter: null,
        }),
        new DataSourceColumn<TrainSchedule>({
          propertyName: 'endStation',
          propertyLabel: 'Станция прибытия',
          dataFilter: null,
          filterable: true,
          sortable: true,
          groupable: false,
          width: null,
          dataSorter: null,
        }),
        new DataSourceColumn<TrainSchedule>({
          propertyName: 'departureTime',
          propertyLabel: 'Время отправления',
          dataFilter: null,
          filterable: false,
          sortable: false,
          groupable: false,
          width: null,
          dataSorter: null,
        }),
      ],
    });

    const reactiveData = reactive({
      dataSource: trainScheduleDataSource,
      // TODO разобрать activeContract и currentContractId
      activeContract: new BusinessContract({
        dateEnd: addYears(Date.now(), 1),
      }),
      currentContractId: null as string | null,
      scheduleInfo: {
        dataLoading: false,
        customerWithTrains: [] as Array<CustomerWithTrains>,
        selectedCustomerId: null as string | null,
        selectedTrainsId: [] as Array<string>,
        trainScheduleWithTrainTimeTables: [] as Array<TrainScheduleWithTrainTimeTable>,
      },
    });

    const contractExpanded = (contracts: BusinessContract[]) => {
      if (contracts.length > 0) {
        selectedContractId.value = contracts[0].id!;
        reactiveData.dataSource.baseUrl = trainScheduleUrl.value;
      }
    };

    const openActiveContractDialog = async () => {
      currentStep.value = 1;
      const url = `${UrlConstants.baseApi}/v1/customers/with-trains`;
      let response: AxiosResponse<{customers: Array<CustomerWithTrains>}>;
      try {
        response = await httpService.get<{customers: Array<CustomerWithTrains>}>(url);
      } catch (e) {
        return;
      }

      createContractDialogIsOpen.value = true;
      reactiveData.scheduleInfo.selectedCustomerId = null;
      reactiveData.scheduleInfo.selectedTrainsId = [];
      reactiveData.scheduleInfo.trainScheduleWithTrainTimeTables = [];

      reactiveData.scheduleInfo.customerWithTrains = response.data.customers;
    };

    const customerTrains = computed(() => {
      if (reactiveData.activeContract.customer == null) {
        return new Array<SimpleModel>();
      }
      const trains = reactiveData.scheduleInfo.customerWithTrains
        .filter((ct) => ct.id == reactiveData.activeContract.customer!.id)
        .flatMap((ct) => ct.trains);

      return trains;
    });

    watch(() => reactiveData.scheduleInfo.selectedCustomerId,
      () => {
        reactiveData.scheduleInfo.selectedTrainsId = [];
      });

    const nextStep = () => {
      currentStep.value++;
    };

    const previousStep = () => {
      currentStep.value--;
    };

    const migrateTrainSchedule = async () => {
      reactiveData.scheduleInfo.dataLoading = true;
      const url = `${UrlConstants.baseApi}/v1/train-schedules/migrate`;
      let response: AxiosResponse<string>;

      try {
        const selectedCustomer = reactiveData.activeContract.customer;
        const selectedTrains = reactiveData.scheduleInfo.selectedTrainsId;

        response = await httpService.post<TrainScheduleMigrateRequest, string>(url, {
          data: {
            contractId: reactiveData.activeContract.id!,
            customerId: selectedCustomer!.id,
            trains: selectedTrains,
          },
        });
      } catch (e) {
        reactiveData.scheduleInfo.dataLoading = false;
        return;
      }

      if (response.status !== 200) {
        reactiveData.scheduleInfo.dataLoading = false;
        return;
      }

      reactiveData.scheduleInfo.dataLoading = false;
      await loadTrainTimeTables();
      nextStep();
    };

    const loadTrainTimeTables = async () => {
      reactiveData.scheduleInfo.dataLoading = true;
      const url = `${UrlConstants.baseApi}/v1/train-schedules/${reactiveData.currentContractId}/train-time-tables`;
      let response: AxiosResponse<TrainScheduleWithTrainTimeTableResponse>;

      try {
        response = await httpService.get<TrainScheduleWithTrainTimeTableResponse>(url, {
          constructorFunction: TrainScheduleWithTrainTimeTableResponse,
        });
      } catch (e) {
        reactiveData.scheduleInfo.dataLoading = false;
        return;
      }
      reactiveData.scheduleInfo.trainScheduleWithTrainTimeTables = [...response.data.trainScheduleWithTrainTimeTables];
      reactiveData.scheduleInfo.trainScheduleWithTrainTimeTables = [...response.data.trainScheduleWithTrainTimeTables];
      reactiveData.scheduleInfo.dataLoading = false;
    };

    const getTimeLabel = (time: number) => {
      if (time === 0) {
        return '';
      }
      return secondsToTime(time, 'HH:mm');
    };

    const saveTrainTimeTables = async () => {
      const url = `${UrlConstants.baseApi}/v1/train-schedules/train-time-tables`;
      let response: AxiosResponse<void>;
      reactiveData.scheduleInfo.dataLoading = true;
      try {
        const trainTimeTables = reactiveData.scheduleInfo.trainScheduleWithTrainTimeTables
          .flatMap((tst) => tst.trainTimeTables);

        response = await httpService.put<TrainTimeTableRequest, void>(url, {
          data: {
            trainTimeTables,
          },
        });
      } catch (e) {
        return;
      } finally {
        reactiveData.scheduleInfo.dataLoading = false;
      }
      createContractDialogIsOpen.value = false;
      reactiveData.currentContractId = null;
      reactiveData.activeContract = new BusinessContract({
        dateEnd: addYears(Date.now(), 1),
      });
    };

    const getContractById = async (contractId: string) => {
      const url = `${UrlConstants.baseApi}/v1/business-contracts/${contractId}`;
      let response: AxiosResponse<ContractResponse>;
      reactiveData.scheduleInfo.dataLoading = true;
      try {
        response = await httpService.get<ContractResponse>(url, {
          constructorFunction: ContractResponse,
        });
      } catch (e) {
        return;
      } finally {
        reactiveData.scheduleInfo.dataLoading = false;
      }
      reactiveData.activeContract = response.data.businessContract;
    };

    const refreshTrainSchedule = async (contractId: string) => {
      await getContractById(contractId);
      await openActiveContractDialog();
    };

    const createContract = async () => {
      const url = `${UrlConstants.baseApi}/v1/business-contracts`;
      let response: AxiosResponse<string>;
      reactiveData.scheduleInfo.dataLoading = true;
      try {
        response = await httpService.post<any, string>(url, {
          data: {
            businessContract: reactiveData.activeContract,
          },
        });
      } catch (e) {
        return;
      } finally {
        reactiveData.scheduleInfo.dataLoading = false;
      }
      reactiveData.currentContractId = response.data;
      await getContractById(reactiveData.currentContractId);
      nextStep();
      dataSource.refresh();
    };

    const generateOrders = async (contractId: string) => {
      const url = `${UrlConstants.baseApi}/v1/business-orders/${contractId}`;
      let response: AxiosResponse<void>;
      reactiveData.scheduleInfo.dataLoading = true;
      try {
        response = await httpService.post<any, void>(url);
      } catch (e) {
        return;
      } finally {
        reactiveData.scheduleInfo.dataLoading = false;
      }
    };

    const changeLocomotiveChanged = (trainTimeTable: TrainTimeTable) => {
      if (trainTimeTable.changeLocomotive) {
        trainTimeTable.changeLocomotiveCrew = true;
      }
    };

    return {
      trainTimeTableTab,
      currentStep,
      createContractDialogIsOpen,
      dataSource,
      reactiveData,
      customerTrains,
      generateOrders,
      refreshTrainSchedule,
      getContractById,
      createContract,
      saveTrainTimeTables,
      getTimeLabel,
      toDateRange,
      loadTrainTimeTables,
      nextStep,
      previousStep,
      contractExpanded,
      openActiveContractDialog,
      migrateTrainSchedule,
      changeLocomotiveChanged,
    };
  },
});
