import { SimpleModel } from '@/models/base/SimpleModel';

export class Station extends SimpleModel {
  public code!: string

  get fullName(): string {
    return `${this.code} - ${this.name}`;
  }

  constructor(init?: Partial<Station>) {
    super();
    Object.assign(this, init);
  }
}
