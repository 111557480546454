import { Type } from 'class-transformer';
import { Station } from '@/models/Station';

export class TrainTimeTable {
  public id = '';

  public departureTime = 0;

  public arrivalTime = 0;

  public stopTime = 0;

  public changeLocomotiveCrew = false;

  public changeLocomotive = false;

  public order = 0;

  @Type(() => Station)
  public station!: Station;
}
