





























import {
  defineComponent,
  ref,
  watch,
} from '@vue/composition-api';
import dateFormats from '@/helpers/DateFormats';

export default defineComponent({
  name: 'RdDatePicker',
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Date,
      required: false,
      default: null,
    },
  },
  emits: ['input'],
  setup: (props, ctx) => {
    const {
      toDate, stringDateToDate, toISO8601, ISOStringDateToDate,
    } = dateFormats();

    const date = ref(props.value ?? new Date());
    ctx.emit('input', date);
    const menuIsOpen = ref(props.isOpen);
    const dateForPicker = ref(toISO8601(date.value));
    const dateForUser = ref(toDate(date.value));

    watch(date, (current) => {
      ctx.emit('input', current);
    });

    const pickerDateUpdated = (newDate: string) => {
      date.value = ISOStringDateToDate(newDate);
      dateForPicker.value = newDate;
      dateForUser.value = toDate(date.value);
    };

    const textDateChanged = (textDate: string) => {
      date.value = stringDateToDate(textDate);
      dateForPicker.value = toISO8601(date.value);
      dateForUser.value = textDate;
    };

    const closePicker = () => {
      menuIsOpen.value = false;
    };

    return {
      date,
      menuIsOpen,
      dateForPicker,
      dateForUser,
      pickerDateUpdated,
      closePicker,
      textDateChanged,
    };
  },
});
